@tailwind base;
@tailwind components;
@tailwind utilities;

.form-control {
  background-color: transparent;
  color: white;
}

.rdtPicker {
  background-color: white !important;
  color: black;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.seating-plan {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Espace entre les rangées */
}

.row {
  display: flex;
  gap: 10px; /* Espace entre les tables */
}

.table,
.bar {
  border: 1px solid #000; /* Contour des tables et du bar */
  padding: 10px 20px; /* Espacement intérieur */
  text-align: center; /* Centre le texte */
}

.table-group {
  display: flex;
  gap: 5px; /* Espace entre les tables groupées */
}

.bar {
  background-color: #ddd; /* Couleur de fond du bar */
}
